<template>
<v-app id="inspire">
    <div class="px-4">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
        <v-container class="container--fluid grid-list-md text-center">
            <h1>Accounts</h1>
        </v-container>
        <v-card elevation="2" outlined shaped tile class="pa-1">
            <div class="d-flex flex-no-wrap justify-space-between" v-if="hasPermission('ROLE_BILLER_SEARCH_ACCOUNT')">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout row wrap>
                            <v-flex xs12 sm6>
                                <v-text-field label="Account Name" v-model="query.accountName" single-line outlined @change="search($event, 'accountName')" data-cy="module-account-name"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-text-field label="Account Number" v-model="query.number" single-line outlined @change="search($event, 'number')" data-cy="module-number"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-text-field label="Reference Number" v-model="query.referenceNumber" single-line outlined @change="search($event, 'referenceNumber')" data-cy="module-reference-number"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6>
                                <v-text-field label="Subscription Plan" v-model="query.subscriptionPlan" single-line outlined @change="search($event, 'subscriptionPlan')" data-cy="module-subscription-plan"></v-text-field>
                            </v-flex>
                            <v-col class="d-flex" cols="12" md="5">
                                <v-autocomplete
                                    clearable chips
                                    :items="billersList" 
                                    item-text="billerName" 
                                    item-value="biller" 
                                    label="Biller" 
                                    data-cy="module-selected-biller"
                                    v-model="selectedBiller" 
                                    return-object
                                    @change="searchBiller">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="d-flex" cols="12" md="5">
                                <v-autocomplete
                                    clearable chips
                                    :items="statusList" 
                                    item-text="name" 
                                    item-value="accountStatus" 
                                    label="Account Status" 
                                    data-cy="module-account-status"
                                    v-model="selectedStatus" 
                                    return-object
                                    @change="searchStatus">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-from">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field label="From" prepend-icon="mdi-calendar" readonly :value="fromDateDisp" v-on="on" data-cy="date-menu-from"></v-text-field>
                                    </template>
                                    <v-date-picker locale="en-in" v-model="query.dateFrom" no-title @input="fromDateMenu = false" :min="minDate" :max="fromMax" data-cy="date-menu-from">
                                        <v-btn text color="primary" @click="clearFromDate()">Clear
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-menu ref="menu" v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field label="To" prepend-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on" data-cy="date-menu-to"></v-text-field>
                                    </template>
                                    <v-date-picker locale="en-in" v-model="query.dateTo" no-title @input="toDateMenu = false" :max="maxDate" :min="toMin" data-cy="date-menu-to">
                                        <v-btn text color="primary" @click="clearToDate()" data-cy="button-clear">Clear
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>
                    <v-container grid-list-md text-xs-center>
                        <v-layout wrap>
                            <v-flex xs12 sm6 v-if="hasPermission('ROLE_BILLER_SEARCH_ACCOUNT')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color=success @click="queryData(true)" data-cy="button-search">
                                    <v-icon dark left> mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-flex>
                            <!-- <v-flex xs12 sm6 v-if="hasPermission('ROLE_BILLER_CREATE_ACCOUNT')">
                                <v-btn :block="$vuetify.breakpoint.mdAndDown" class="mt-2" color="success" @click.stop="showUserForm('CREATED')">Create Account</v-btn>
                                <user-form ref="userForm" :selected-item="selectedUser" :is-show="showFormDialog" :form-type="userFormType" :roles="roles" :paymentAgents="paymentAgents" :isNotPaymentAgent=isNotPaymentAgent v-on:close-dialog="closeDialog" v-on:save-user="saveUser" />
                            </v-flex> -->
                        </v-layout>
                    </v-container>
                </v-card-title>
            </div>
        </v-card>

        <v-data-table :hide-default-header="true" :headers="headers" :items="displayAccounts" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalAccounts" :footer-props="{'items-per-page-options': [10]}" class="elevation-2 mt-4 px-4">
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                            <span class="d-flex justify-center">{{header.text}}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.accountName }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.number }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.biller }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.accountStatus }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.referenceNumber }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.subscriptionPlan }} </span></td>
                        <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                        <td>
                            <span class="d-flex justify-center">
                                <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewUser(item)">
                                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                                </v-btn>
                                <!-- <v-btn fab dark x-small color="green" @click.stop="showUserForm('UPDATE', item)" v-if="hasPermission('ROLE_BILLER_UPDATE_ACCOUNT')">
                                    <v-icon dark medium>mdi-pencil</v-icon>
                                </v-btn> -->
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Found no results.
                </v-alert>
            </template>
        </v-data-table>
    </div>
</v-app>
</template>

<script>
import API from '@/api/API.js'
import Loading from '@/views/components/Loading.vue'
import UserForm from '@/views/components/UserForm.vue'
import DetailsForm from '@/views/components/TransactionForm.vue'
import moment from 'moment';
import {
    mapGetters
} from 'vuex';

export default {
    name: 'Users',
    components: {
        Loading,
        UserForm,
        DetailsForm
    },
    data() {
        return {
            //filter/search variables
            filterQry: {
                qryName: "",
            },
            headers: [],
            paymentAgents: [],
            isNotPaymentAgent: false,
            displayAccounts: [],
            remainderAccounts: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            userFormType: 'CREATE',
            selectedUser: {},
            roles: [],
            statuses: [],
            statusList: this.statuses ?? [],
            selectedStatus: '',
            billers: [],
            billersList: this.billers ?? [],
            selectedBiller: '',
            page: 1,
            totalAccounts: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: '',
                item: {}
            },
            query: {
                accountName: '',
                number: '',
                referenceNumber: '',
                subscriptionPlan: '',
                accountStatus: '',
                biller: '',
                dateFrom: '',
                dateTo: '',
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false
        }
    },
    watch: {
        options: {
            handler() {
                console.log('1')
                this.queryData()
                this.setData()
            },
        },
        statuses: {
            handler(value) {
                this.statusList = value
            }
        },
        billers: {
            handler(value) {
                this.billersList = value
            }
        },
        deep: true,
    },
    computed: {
        //get role attributes
        ...mapGetters([
            'attributes',
            'user',
            'authorities'
        ]),
        fromDateDisp() {
            this.toMin = moment().format(this.query.dateFrom)
            return this.query.dateFrom;
        },
        toDateDisp() {
            this.fromMax = moment().format(this.query.dateTo)
            return this.query.dateTo;
        }
    },
    async mounted() {
        this.setHeaders()
        this.setDefaultDateRange()
        //this.queryData()
    },
    methods: {
        hasPermission(authority) {
          if(this.authorities.some(x => x.name === authority)){
            return true
          }
          return false
        },
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage
                const parameters = {
                    offset: this.totalAccounts,
                    limit: 1,
                    accountName: this.query.accountName,
                    number: this.query.number,
                    referenceNumber: this.query.referenceNumber,
                    subscriptionPlan: this.query.subscriptionPlan,
                    billerId: this.selectedBiller.id,
                    statusId: this.selectedStatus.id,
                    dateFrom: this.query.dateFrom,
                    dateTo: this.query.dateTo
                }

                const accountListResponse = await API.getBillerAccounts(pageNumber, limit, parameters)
                if (!accountListResponse || accountListResponse.error) {
                    //error getting data
                    console.log(`${accountListResponse.error}`)
                } else {
                    var filteredList = accountListResponse.accounts
                    if (page > 1 && this.remainderAccounts.length > 0) {
                        filteredList = this.remainderAccounts.concat(filteredList)
                    }
                    this.totalAccounts = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayAccounts = filteredList.slice(0, end)
                    this.remainderAccounts = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalAccounts / itemsPerPage

                    const hasMoreResponse = await API.getBillerAccounts(this.totalAccounts, 1, parameters)
                    if (!hasMoreResponse || hasMoreResponse.error) {
                        //error getting data
                        console.log(`${hasMoreResponse.error}`)
                    } else {
                        var hasMoreList = hasMoreResponse.users
                        if(hasMoreList.length > 0) {
                          this.totalAccounts = this.totalAccounts + 1
                          this.numberOfPages = this.numberOfPages + 1
                        }
                    }
                }
                this.loading = false
            } catch (e) {
                console.log(e)
                this.loading = false
            }
        },
        async setData() {
            try {
                const listResponse = await API.getStatusList()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.statuses = listResponse.statuses
                }
            } catch (e) {
                console.log(e)
            }

            try {
                const listResponse = await API.getBillersList()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.billers = listResponse.billers
                }
            } catch (e) {
                console.log(e)
            }
        },
        
        setHeaders() {
            var headers = [
                {
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'Account Name',
                    value: 'accountName'
                },
                {
                    text: 'Account Number',
                    value: 'number'
                },
                {
                    text: 'Biller',
                    value: 'biller'
                },
                {
                    text: 'Status',
                    value: 'accountStatus'
                },
                {
                    text: 'Reference Number',
                    value: 'referenceNumber'
                },
                {
                    text: 'Subscription Plan',
                    value: 'subscriptionPlan'
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated'
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false
                }
            ]
            this.headers = headers
        },
        showUserForm(what, item = {}) {
            this.userFormType = what
            this.selectedUser = item
            this.showFormDialog = true
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        async saveUser(value) {
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                if (value.formType === 'CREATED') {
                    this.loadingMessage = `Creating new user <strong><i>${value.username}</i></strong>`
                    setTimeout(async () => {
                        const createResponse = await API.createUser(value)
                        console.log(createResponse)
                        this.loadingFinished = true
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = createResponse.error
                        } else {
                            this.loadingMessage = `Successfully created user <strong><i>${value.username}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.userForm.resetForm()
                        }
                    }, 2000);

                } else if (value.formType === 'UPDATE') {
                    this.loadingMessage = `Updating user <strong><i>${value.username}</i></strong>`
                    setTimeout(async () => {
                        const updateResponse = await API.updateUser(value)
                        this.loadingFinished = true
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false
                            this.loadingMessage = updateResponse.error
                        } else {
                            this.loadingMessage = `Successfully updated user <strong><i>${value.username}</i></strong>`
                            this.showFormDialog = false
                            this.$refs.userForm.resetForm()
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command"
                    this.loadingFinished = true
                }
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        viewUser(user) {
            console.log(user)
            this.details.title = user.username
            this.details.item = user
            this.isShowDetails = true
        },
        search(event, type) {
            this.query[type] = event
        },
        getToday() {
            const today = moment().format("yyyy-MM-DD")
            return today
        },
        setDefaultDateRange() {
            this.query.dateFrom = ''//this.getToday();
            this.query.dateTo = ''
            this.maxDate = moment().add(1, "days").format("yyyy-MM-DD")
            this.fromMax = moment().add(1, "days").format("yyyy-MM-DD")
            this.toMin = moment().format(this.query.dateFrom)
        },
        clearToDate() {
            this.query.dateTo = ''
            this.toDateMenu = false
        },
        clearFromDate() {
            this.query.dateFrom = ''
            this.fromDateMenu = false
        },
        searchStatus: async function (val) {
            this.selectedStatus = val
        },
        searchBiller: async function (val) {
            this.selectedBiller = val
        },
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%
}
</style>
