<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update User</span>
                <span class="headline" v-else>Create New User</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Username*" :readonly="formType === 'UPDATE'" v-model="form.username" required hint="one word, camel case *UserName*" :rules="[rules.requiredField]" data-cy="form-username"></v-text-field>
                            </v-flex>
                            <v-flex xs12 v-if="formType === 'CREATED'">
                                    <v-text-field label="Password*" v-model="form.password" required :rules="[rules.requiredField]" :append-icon="passwordType.password ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (passwordType.password = !passwordType.password)" :type="passwordType.password ? 'password' : 'text'" data-cy="form-password"></v-text-field>
                                </v-flex>
                                <v-flex xs12 v-if="formType === 'CREATED'">
                                    <v-text-field label="Confirm Password*" v-model="form.confirmPassword" required :rules="[rules.requiredField, rules.matchPassword]" :append-icon="passwordType.confirmPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (passwordType.confirmPassword = !passwordType.confirmPassword)" :type="passwordType.confirmPassword ? 'password' : 'text'" data-cy="form-confirm-password"></v-text-field>
                                </v-flex>
                            <v-flex xs12>
                                <v-text-field readonly label="Suggested Password" v-model="generatedPassword" append-icon='mdi-content-copy' @click:append="copyGeneratedPassword" data-cy="form-generated-password"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="First Name*" v-model="form.firstName" required :rules="[rules.requiredField]" data-cy="form-firstname"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Last Name*" v-model="form.lastName" required :rules="[rules.requiredField]" data-cy="form-lastname"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Email*" v-model="form.email" required :rules="[rules.requiredField, rules.properEmail]" data-cy="form-email"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Mobile Number" v-model="form.mobileNumber" :rules="[rules.mobileNumber]" data-cy="form-mobile-number"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Remarks" v-model="form.remarks" data-cy="form-remarks"></v-text-field>
                            </v-flex>
                            <v-flex xs12 v-if="isNotPaymentAgent">
                                <v-autocomplete clearable chips :items="paymentAgentList" item-text="name" item-value="code" label="Payment Agent" v-model="form.paymentAgent" return-object data-cy="form-payment-agent">
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12>
                                <v-select :items="roleList" item-text="name" item-value="name" label="Role*" v-model="form.role" required :rules="[rules.requiredSelection]" multiple return-object data-cy="form-roles">
                                  <v-list-item
                                    slot="prepend-item"
                                    ripple
                                    @click="toggle"
                                    :data-cy="'form-module-' + roleList"
                                    v-if="roleList.length > 0"
                                  >
                                    <v-list-item-action>
                                      <v-icon :color="form.role.length > 0 ? 'indigo darken-4' : ''" :data-cy="'form-module-' + roleList">{{ icon }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>Select All</v-list-item-title>
                                  </v-list-item>
                                  <v-divider
                                    slot="prepend-item"
                                    class="mt-2"
                                  ></v-divider>
                                  <v-divider
                                    slot="append-item"
                                    class="mb-2"
                                  ></v-divider>
                                </v-select>
                            </v-flex>
                            <v-flex xs12 sm6 md4>
                                <v-switch v-model="form.isEnabled" :label="`Is Enabled: ${form.isEnabled ? form.isEnabled.toString() : 'false'}`" data-cy="form-switch"></v-switch>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
import { copyToClipboard } from 'quasar'
export default {
    props: {
        isShow: Boolean,
        isNotPaymentAgent: Boolean,
        formType: String,
        selectedItem: Object,
        roles: Array,
        paymentAgents: Array,
    },
    computed: {
        populatedForm() {
            return {
                username: this.form.username,
                firstName: this.form.firstName,
                lastName: this.form.lastName,
                isEnabled: this.form.isEnabled,
                password: this.form.password,
                confirmPassword: this.form.confirmPassword,
                email: this.form.email,
                mobileNumber: this.form.mobileNumber,
                remarks: this.form.remarks,
                paymentAgent: this.form.paymentAgent,
                role: this.formatRoleList(true, this.form.role),
                formType: this.formType
            }
        },
        haveAllRole () {
          return this.form.role.length === this.roleList.length
        },
        selectedRole () {
          return this.form.role.length > 0 && !this.haveAllRole
        },
        icon () {
          if (this.haveAllRole) return 'mdi-close-box'
          if (this.selectedRole) return 'mdi-minus-box'
          return 'mdi-checkbox-blank-outline'
        },
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        paymentAgents: {
            handler(value) {
                this.paymentAgentList = value
            }
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                    this.generatedPassword = this.generatePassword()
                }
            }
        },
        roles: {
            handler(value) {
              this.roleList = value ? this.formatRoleList(false, value) : []
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            roleList: this.roles ? this.formatRoleList(false, this.roles) : [],
            paymentAgentList: this.paymentAgents ?? [],
            form: {
              role: [],
              paymentAgent: {}
            },
            passwordType: {
                password: String,
                confirmPassword: String
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
                matchPassword: v => v === this.form.password || "Does not match new password.",
                properEmail: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid. Ex. juandelacruz@apollo.com.ph',
                mobileNumber: v => !v || /^(09)\d{9}$/.test(v) || 'Mobile number must be valid. Ex. starts with (09) followed by xxxxxxxxx, where x = numeric character only', 
                ///^(09|\+639)\d{9}$/ <- if needed full philippine mobile number 
            },
            generatedPassword: ""
        }
    },
    mounted() {
        this.initializeForm()
        this.generatedPassword = this.generatePassword()
    },
    methods: {
      formatRoleList(isPopulated, list) {
        if(isPopulated) {
          try {
            var formattedRole = JSON.stringify(list.map(({name}) => ({name})))
            return formattedRole.replace(/"/g, '\"')
          } catch(e) {
            return list
          }
        } else {
          try {
            return list.map((role) => ({name: role}))
          } catch(e) {
            return list
          }
        }
      },
        toggle () {
          this.$nextTick(() => {
            if (this.haveAllRole) {
              this.form.role = []
            } else {
              this.form.role = this.roleList.slice()
            }
          })
        },
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-user', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    username: undefined,
                    firstName: undefined,
                    lastName: undefined,
                    password: undefined,
                    confirmPassword: undefined,
                    email: undefined,
                    isEnabled: false,
                    mobileNumber: undefined,
                    remarks: undefined,
                    role: [],
                    paymentAgent: undefined,
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
              var selectedRoles = []
              var list = this.selectedItem.roles
              if(list) {
                if(Object.prototype.toString.call(list) === '[object Object]') {
                  if(list.role) {
                    selectedRoles.push({name: list.role})
                  } else {
                    selectedRoles.push({name: JSON.stringify(list)})
                  }
                } else if(Object.prototype.toString.call(list) === '[object Array]') {
                  for (var index in list) {
                    console.log(index, list[index])
                    if(list[index] !== "") {
                      selectedRoles.push({name: list[index]})
                    }

                  }
                } else {
                  selectedRoles.push({name: list})
                }
              }
                this.form = {
                    username: this.selectedItem.username,
                    firstName: this.selectedItem.firstName,
                    lastName: this.selectedItem.lastName,
                    isEnabled: this.selectedItem.isEnabled,
                    email: this.selectedItem.email,
                    mobileNumber: this.selectedItem.mobileNumber,
                    remarks: this.selectedItem.remarks,
                    role: selectedRoles,
                    paymentAgent: this.selectedItem.paymentAgent ?? {},
                    password: undefined,
                    confirmPassword: undefined
                }
            }
        },
      generatePassword () {
        let CharacterSet = ''
        let password = ''
        let size = 8

          CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
          CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
          CharacterSet += '0123456789'
          CharacterSet += '![]{}()%&*$#^~@|'
        for (let i = 0; i < size; i++) {
          password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
        }

      return password
      },
        copyGeneratedPassword () {
          copyToClipboard(this.generatedPassword)

          // this.$q.notify({
          //   progress: true,
          //   type: 'positive',
          //   message: `Generated password has been copied to clipboard.`
          // })
      }
    }
}
</script>
