<template>
<v-container row justify-center>
    <v-dialog max-width="750px" persistent v-model="isShowDetails">
        <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
        <v-card ref="formCard">
            <v-card-title class="headline">
                {{details.title}}
            </v-card-title>
            <v-card-text>
                <v-row v-for="(field, index) of getFields()" :key="index">
                    <v-col cols="12" v-if="isJSON(field[1])">
                        <v-textarea auto-grow rows="1" :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-text-field :value="stringify(field[1])" :label="convertCamelCase(field[0]).toUpperCase()" readonly></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card v-if="isStipMerchant()">
                <v-card-title class="green--text headline">
                    STIP RECONNECT ATTEMPTS: {{ this.totalReports }}
                </v-card-title>

                <v-data-table :hide-default-header="true" :hide-default-footer="true" :headers="headers" :items="displayReports" :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options" :server-items-length="totalReports" :footer-props="{'items-per-page-options': [100]}">
                    <template v-slot:header="{ props: { headers } }">
                            <thead>
                                <tr :colspan="headers.length">
                                    <th v-for="header in headers" :key="header.text">
                                        <span class="d-flex justify-center">{{header.text}}</span>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                    <td> <span class="d-flex justify-center"> {{ item.reasonCode }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.reasonDescription }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                                </tr>
                            </tbody>
                        </template>
                        <template v-slot:no-results>
                            <v-alert :value="true" color="error" icon="warning">
                                Found no results.
                            </v-alert>
                        </template>
                </v-data-table>
            </v-card>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-flex xs12>
                    <v-btn color="green darken-1" text @click="close" data-cy="form-close-button">
                        Close
                    </v-btn>
                </v-flex>
                <v-flex>
                    <v-btn color="green darken-1" text @click="markDeclined" v-if="isValidStatus('DECLINED') && !isDeclinedStatus()" data-cy="form-declined-button">
                        Mark DECLINED
                    </v-btn>
                </v-flex>
                <v-flex>
                    <v-btn color="green darken-1" text @click="markForValidation" v-if="isValidStatus('FOR VALIDATION') && !isDeclinedStatus()" data-cy="form-for-validation-button">
                        Mark FOR VALIDATION
                    </v-btn>
                </v-flex>
                <v-flex>
                    <v-btn color="green darken-1" text @click="markPosted" v-if="isValidStatus('POSTED') && !isDeclinedStatus()" data-cy="form-posted-button">
                        Mark POSTED
                    </v-btn>
                </v-flex>
                <v-flex>
                    <v-btn color="green darken-1" text @click="markPosted" v-if="isDeclinedStatus()" data-cy="form-posted-button">
                        Mark as POSTED
                    </v-btn>
                </v-flex>
                <v-flex>
                    <v-btn color="green darken-1" text @click="markPending" v-if="isValidPendingStatus()" data-cy="form-pending-button">
                        Mark as PENDING
                    </v-btn>
                </v-flex>
                <v-flex>
                    <v-btn color="green darken-1" text @click="sendSale" v-if="isValidStatus('POSTED') && !isDeclinedStatus()" data-cy="form-send-sale-button">
                        Send Sale
                    </v-btn>
                </v-flex>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import SwitchAPI from '@/api/SwitchAPI'
import Loading from '@/views/components/Loading.vue'

export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        details: Object
    },
    components: {
        Loading
    },
    watch: {
        isShow: {
            handler(value) {
                this.isShowDetails = value
                if(this.details.isStipMerchant = true && value) {
                    this.queryData(false);
                }
            }
        },
        deep: true,
    },
    details: {
        handler(value) {
            this.itemDetails = value
        },
        deep: true,
    },
    data() {
        return {
            headers: [
                {
                    text: 'Reason Code',
                    value: 'reasonCode'
                },
                {
                    text: 'Description',
                    value: 'description'
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated'
                },
            ],
            isShowDetails: this.isShow ?? false,
            itemDetails: this.details,
            loading: true,
            loadingMessage: "",
            loadingFinished: false,
            page: 1,
            totalReports: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            displayReports: [],
            remainderReports: [],
            isHtml: false,
        }
    },
    methods: {
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                var transactionList = undefined
                
                const parameters = {
                    transactionId: this.details.item.id,
                }
                transactionList = await SwitchAPI.filterStipTransaction(parameters)
                if (!transactionList || transactionList.error) {
                    //error getting data
                    console.log(`${transactionList.error}`)
                } else {
                    var filteredList = transactionList.data
                    if (page > 1 && this.remainderReports.length > 0) {
                        filteredList = this.remainderReports.concat(filteredList)
                    }
                    this.totalReports = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayReports = filteredList.slice(0, end)
                    this.remainderReports = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalReports / itemsPerPage
                }
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
        getFields() {
            const arrOfArrays = Object.entries(this.itemDetails.item);

            const sorted = arrOfArrays.sort((a, b) => {
            return a[0].localeCompare(b[0]);
            });

            return sorted;
        },
        convertCamelCase(string) {
            return string.replace(/([a-z])([A-Z])/g, '$1 $2');
        },
        close() {
            this.isShowDetails = false
            this.$emit('close-dialog', 'details')
        },
        closeDialog(type) {
            if (type === 'loading') {
               this.loadingDialog = false
            } 
        },
        isJSON(value) {
          return Object.prototype.toString.call(value) === '[object Array]' || Object.prototype.toString.call(value) === '[object Object]'
        },
        stringify(value) {
          if(Object.prototype.toString.call(value) === '[object Array]') {
            var items = []
            for(var index in value) {
              if(value[index].role) {
                items.push(value[index].role)
              }
            }
            if(items.length > 0) {
              return items.toString()
            }
            return JSON.stringify(value)
          } else if(Object.prototype.toString.call(value) === '[object Object]') {
            if(value.role) {
              return value.role
            }
            return JSON.stringify(value)
          } else {
            return value.toString()
          }
        },
        markForValidation() {
            this.details.status = "FOR VALIDATION"
            this.details.payconnectReference = this.details.title
            this.$emit("update-status", this.details);
            this.$refs.formCard.$el.scrollIntoView({
            behavior: "smooth",
            });
        },
        markDeclined() {
            this.details.status = "DECLINED"
            this.details.payconnectReference = this.details.title
            this.$emit("update-status", this.details);
            this.$refs.formCard.$el.scrollIntoView({
            behavior: "smooth",
            });
        },
        markPosted() {
            this.details.status = "POSTED"
            this.details.payconnectReference = this.details.title
            this.$emit("update-status", this.details);
            this.$refs.formCard.$el.scrollIntoView({
            behavior: "smooth",
            });
        },
        markPending() {
            this.details.status = "PENDING"
            this.details.payconnectReference = this.details.title
            this.$emit("update-status", this.details);
            this.$refs.formCard.$el.scrollIntoView({
            behavior: "smooth",
            });
        },
        sendSale() {
            this.details.merchantId = this.details.merchantId
            this.details.payconnectReference = this.details.title
            this.$emit("send-sale", this.details);
            this.$refs.formCard.$el.scrollIntoView({
            behavior: "smooth",
            });
        },
        sendReversal() {
            this.details.merchantId = this.details.merchantId
            this.details.payconnectReference = this.details.title
            this.$emit("send-reversal", this.details);
            this.$refs.formCard.$el.scrollIntoView({
            behavior: "smooth",
            });
        },
        isStipMerchant() {
            if(this.details.isStipMerchant = true) {
                return true
            } else {
                return false 
            }
        },
        isValidStatus(status) {
            if(this.details.status === status) {
                return false
            } else {
                if(this.details.status === "PAID" || this.details.status === "STIP"  || this.details.status === "FOR VALIDATION" ) {
                    return true
                } else {
                    return false
                }
            }
        }, 
        isValidPendingStatus() {
            if(this.details.status === "POSTED") {
                return true
            } else {
                return false
            }
        },
        isDeclinedStatus() {
            if(this.details.status === "DECLINED") {
                return true
            } else {
                return false
            }
        }
    }
}
</script>
